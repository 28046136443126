import React,{Component} from 'react'
import axios from 'axios';
import { APIURL } from '../../../config/config';
import NumberFormat from 'react-number-format'

const port = window.env.PORT;

// user_type
class CarLeasingView extends Component {
  constructor (props){
    super(props)
    this.state = {
      lists: [],
      car_id : this.props.car.car_id,
      // leasingName: ['SCB', 'GE', 'TA', 'KK', 'TIS']
      // leasingName: ['AYCAL', 'TBANK', 'TISCO', 'CIMB', 'SCB']
      leasingName: []
    }
  }

  async componentDidMount() {
    this.mounted = true
    await this.loadCarLeasingForm();
    await this.loadCarLeasing();
  }

  componentWillUnmount() {
    this.mounted = false
  }

  componentWillReceiveProps(nextProps) {
     if(nextProps.carLeasingList.length > 0){
       //console.log('===nextProps car_id=' + nextProps.car.car_id)
        if(nextProps.carLeasingList[0].car_id === nextProps.car.car_id){
          this.setState({lists: nextProps.carLeasingList});
        }
     }

     if(nextProps.car !== this.props.car) {
        this.setState({
          car_id: nextProps.car.car_id
        },()=>{
          this.loadCarLeasing();
        });
     }
  }

  loadCarLeasingForm() {
    let url = APIURL + '/leasing_setting/';
    axios.get(url)
      .then(res => {
        var leasingName = []
        res.data.forEach(leasing => {
          leasingName.push(leasing.name_credit)
        })

        if(this.mounted) {
          this.setState({leasingName: leasingName})
        }

      });
  }

  loadCarLeasing() {
    //console.log('===loadCarLeasing car_id=' + this.state.car_id)
    let uri =  APIURL + '/car_leasing/'+this.state.car_id;
    axios.get(uri)
      .then(res => {
        if(this.mounted) {
          this.setState({ lists: res.data })
        }
      }).catch(error => {
        if(this.mounted) {
          this.setState({ lists: [] })
        }
    })
  }

  render(){
    if(this.state.lists.length === 0){
      let trLeasing = this.state.leasingName.map((leasing, index) => {
        return (
          <tr key={index}>
            <td style={{width: 100}}>{leasing}</td>
            <td style={{width: 100}}>&nbsp;-&nbsp;</td>
            <td style={{width: 100}}>&nbsp;-&nbsp;</td>
            <td style={{width: 100}}>&nbsp;-&nbsp;</td>
          </tr>
        )
      })
      return (
        <div>
          <table className="tableWhitesmoke" style={{marginTop: 8}}>
            <tbody>
             {trLeasing}
            </tbody>
          </table>
        </div>
      )
    }else {
      let trLeasing = this.state.lists.map((leasing, index) => {
        //if(leasing.car_leasing_name!=='MPRICE') {
          return (
            <tr key={index}>
              <td style={{width: 100}}>{leasing.car_leasing_name}</td>
              <td style={{width: 100}}>
                <NumberFormat
                  value={leasing.car_leasing_price_median} displayType={'text'} thousandSeparator={true}
                  prefix={'฿'}/>
              </td>
              <td style={{width: 100}}>
                <NumberFormat
                  value={leasing.car_leasing_price} displayType={'text'} thousandSeparator={true}
                  prefix={'฿'}/>
              </td>
              <td style={{width: 100}}>
                <NumberFormat
                  value={leasing.car_leasing_price_nc} displayType={'text'} thousandSeparator={true}
                  prefix={'฿'}/>
              </td>
            </tr>
          )
      })

      return (
        <div>
          <table className="tableWhitesmoke" style={{marginTop: 8}}>
            <thead>
            {
              port === '9021' ||  port === 9021 ? (
                <tr>
                  <th>ธนาคาร</th>
                  <th>ปกติ</th>
                  <th>เครดิต</th>
                  <th>เครดิตดีมาก</th>
                </tr>
              ) : (
                <tr>
                <th>ธนาคาร</th>
                <th>ราคากลาง</th>
                <th>เครดิต</th>
                <th>ไม่เครดิต</th>
              </tr>
              )
            }


            </thead>
            <tbody>
            {trLeasing}
            </tbody>
          </table>
        </div>
      )
    }
  }
}

/*const styles = {
  tableLeasing: {
    backgroundColor: 'tableWhitesmoke'
  }
}*/

export default CarLeasingView ;
